<template>
  <div class="order-detail">
    <order-info hand-flag="xq" :dishInfo="dishPacList" :deliveryInfo="deliveryForm" />
  </div>
</template>

<script>
import orderInfo from './components/orderInfo.vue';
import { getMyOrderDetailUrl } from './api.js';

export default {
  name: 'eldCanOrderDetail',
  components: {
    orderInfo
  },
  data() {
    return {
      dishPacList: [],
      deliveryForm: {
        userId: '',
        userName: '',
        mobileNum: '',
        houseAddress: '',
        orderDishes: '',
        orderAmount: void 0,
        deliveryTime: ''
      }
    };
  },
  computed: {},
  watch: {},
  created() {
    this.getDetail();
    // this.dishPacList = new Array(4).fill(0).map((e,i) => ({
    //   dishTypeId: i + 1,
    //   dishId: i + 1,
    //   dishName: '红烧肉红烧肉红烧肉红烧肉红烧肉',
    //   packageId: '',
    //   packageName: '',
    //   price: +(Math.random() * 100).toFixed(2),
    //   quantity: Math.floor(Math.random() * 5 + 1),
    // }));
  },
  methods: {
    getDetail() {
      if (!this.$route.query.id) return;
      this.$axios.get(getMyOrderDetailUrl, { params: { id: this.$route.query.id } }).then(res => {
        if (res && res.code === 200) {
          res.data = res.data || {};
          res.data.orderDetailList = Array.isArray(res.data.orderDetailList) ? res.data.orderDetailList : [];
          this.deliveryForm = res.data;
          this.dishPacList = res.data.orderDetailList;
        }
      }).catch(() => {});
    }
  }
};
</script>

<style lang="less" scoped>
.order-detail {
  box-sizing: border-box;
  height: 100%;
  height: calc(100% - constant(safe-area-inset-bottom));
  height: calc(100% - env(safe-area-inset-bottom));
  overflow-x: hidden;
  overflow-y: auto;
}
.order-info {
  padding: 20px 30px;
}
</style>
